<template>
  <v-card class="d-flex align-center font-weight-bold justify-center pa-4 fill-width fill-height green lighten-4" light outlined @click.prevent="IMPORT_DATA()">
    <v-icon small class="mr-1">
      mdi-file-import
    </v-icon>
    Import
    <div style="width:1px;right:1px;position:fixed;left:-10px;top:-10px;z-index:-1;opacity:0;">
      <xlsx-read :file="file" @parsed="parsed" />
    </div>
    <v-dialog
      v-model="dialogConfirmUpload"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title class="justify-center">
          {{ sheetSelected ? 'Please wait ...' : 'Select Sheets' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4" style="max-height: 300px;">
          <v-card v-for="(s, iS) in sheets" :key="'sheet-' + iS" class="ma-2 pa-4 d-flex fill-width rounded-lg font-weight-bold justify-center c-pointer" outlined @click.prevent="ProcessSheet(s)">
            {{ iS }}
          </v-card>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center">
          <v-btn
            color="grey darken-1"
            outlined
            depressed
            small
            class="rounded-pill text-capitalize px-8"
            @click="dialogConfirmUpload = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { XlsxRead } from 'vue-xlsx'
export default {
  components: {
    XlsxRead
  },
  data: () => ({
    dialogConfirmUpload: false,
    file: null,
    sheets: [],
    sheetSelected: null
  }),
  methods: {
    ProcessSheet (sheet) {
      if (sheet) {
        if (!confirm('Continue Import this Sheet ?')) {
          return false
        }
        this.sheetSelected = Object.assign({}, sheet)
        this.dialogConfirmUpload = false
        const obj = {
          A: 'no', B: 'name', C: 'birthday_place', D: 'birthday_date', E: 'sex', F: 'nik', G: 'passport', H: 'passport_expire', I: 'stay_ktkln', J: 'ln_arrival', K: 'stay_address', L: 'ln_country', M: 'ln_country_id', N: 'ln_province', O: 'ln_city', P: 'ln_postal_code', Q: 'phone_code', R: 'phone', S: 'email', T: 'visa', U: 'visa_validity_from', V: 'visa_validity_to', W: 'estimated_stay_year', X: 'estimated_stay_month', Y: 'stay_reason', Z: 'stay_data1', AA: 'stay_data2', AB: 'stay_name', AC: 'stay_address', AD: 'stay_pptkis', AE: 'stay_agent', AF: 'stay_name', AG: 'stay_data1', AH: 'stay_data2', AI: 'stay_education_length', AJ: 'stay_notes', AK: 'emergency_ln', AL: 'emergency_relation_ln', AM: 'emergency_email_ln', AN: 'emergency_phone_code_ln', AO: 'emergency_phone_ln', AP: 'emergency', AQ: 'emergency_relation', AR: 'emergency_email', AS: 'emergency_phone_code', AT: 'emergency_phone'
        }
        let startAt = 0
        if (sheet.A1 ? parseInt(sheet.A1.v) : 0) {
          startAt = 1
        } else if (sheet.A2 ? parseInt(sheet.A2.v) : 0) {
          startAt = 2
        } else if (sheet.A3 ? parseInt(sheet.A3.v) : 0) {
          startAt = 3
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Format sheet tidak valid!' })
          return false
        }
        const parsedData = []
        while (parseInt(startAt)) {
          const data = {
            id: null,
            status: 1
          }
          Object.keys(obj).forEach((key) => {
            data[obj[key]] = (sheet[key + '' + startAt] ? sheet[key + '' + startAt].v : '') || null
            if (obj[key] === 'ln_city') {
              data.ln_city = (data.ln_city.replace('KUALA LUMPUR', 'W.P. KUALA LUMPUR').replace('LABUAN', 'W.P. LABUAN').replace('PUTRAJAYA', 'W.P. PUTRAJAYA').replace('PENANG', 'PULAU PINANG') || '').trim().toUpperCase()
            }
          })

          data.ln_province = (data.ln_city || '')
          const getNegeri = this.$store.state.kbri_area.find(r => ((r.name + ' ').trim() === (data.ln_province + ' ').trim() || (r.name + ' ').trim() === (data.ln_city + ' ').trim()))
          if (getNegeri) {
            data.area = parseInt(getNegeri.id) || 0
            data.ln_province = getNegeri.name || ''
            if (data.ln_province === 'PERLIS' || data.area === 9) {
              data.area = 9
              data.ln_province = 'PERLIS'
              data.area_sub = 73
              data.ln_city = 'PERLIS'
            } else if (data.ln_province === 'W.P. KUALA LUMPUR' || data.area === 14) {
              data.area = 14
              data.ln_province = 'W.P. KUALA LUMPUR'
              data.area_sub = 150
              data.ln_city = 'W.P. KUALA LUMPUR'
            } else if (data.ln_province === 'W.P. LABUAN' || data.area === 15) {
              data.area = 15
              data.ln_province = 'W.P. LABUAN'
              data.area_sub = 159
              data.ln_city = 'W.P. LABUAN'
            } else if (data.ln_province === 'W.P. PUTRAJAYA' || data.area === 14) {
              data.area = 16
              data.ln_province = 'W.P. PUTRAJAYA'
              data.area_sub = 160
              data.ln_city = 'W.P. PUTRAJAYA'
            }
          } else {
            data.area = 0
            data.ln_province = ''
          }

          data.birthday_date = this.$ConvertExcelDate(data.birthday_date) || ''

          data.passport = (data.passport || '').toUpperCase().trim()
          data.visa = (data.visa || '').toUpperCase().trim()
          data.phone_code = data.phone_code ? '+' + data.phone_code : ''
          data.emergency_phone_code = data.emergency_phone_code ? '+' + data.emergency_phone_code : ''
          data.emergency_phone_code_ln = data.emergency_phone_code_ln ? '+' + data.emergency_phone_code_ln : ''

          data.estimated_stay = (parseInt(data.estimated_stay_year) || 0) * 365 // estimate days in year
          data.estimated_stay += (parseInt(data.estimated_stay_month) || 0) * 30 // estimate days in month
          data.estimated_stay = parseInt(data.estimated_stay) || 0

          delete data.estimated_stay_year
          delete data.estimated_stay_month
          delete data.no
          data.role = 'customer'
          data.ln_country = data.ln_country || 'Malaysia'
          data.ln_country_id = data.ln_country_id || 458
          parsedData.push(Object.assign({}, data))
          startAt++
          if (!(sheet['A' + startAt] ? parseInt(sheet['A' + startAt].v) : 0)) {
            startAt = 0
            // console.log('stop!')
          } else {
            // console.log('continue: ', startAt)
          }
        }
        if (parsedData.length) {
          this.$store.dispatch('customer/CUSTOMER_BULK_PROCESS_BY_ADMIN', parsedData)
            .then((res) => {
              if (res.status) {
                this.$emit('reloadTable', true)
                this.$store.dispatch('TOAST', { show: true, message: 'Berhasil di Import' })
              } else {
                this.$store.dispatch('TOAST', { show: true, message: 'Ops, terjadi kesalahan!' })
              }
            })
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Data kosong!' })
        }
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Tidak ada sheet/halaman dipilih!' })
      }
      // this.dialogAccount.data = {
      //   id: null,
      //   nik: '',
      //   passport: '',
      //   passport_expire: '',
      //   name: '',
      //   marriage_status: '', // Kawin, Belum Kawin, Cerai Hidup, Cerai Mati
      //   religion: '', // Islam, Kristen, Katholik, Budha, Hindu, Konghuchu, Kepercayaan, Lainnya
      //   birthday_place: '',
      //   birthday_date: '',
      //   phone_code: '+60',
      //   phone: '',
      //   email: '',
      //   address: '',

      //   sex: '', // Laki-laki / Perempuan

      //   ln_arrival: '',
      //   ln_address: '',
      //   ln_country: '',
      //   ln_country_id: 458,
      //   ln_province: '',
      //   ln_city: '',
      //   ln_postal_code: '',
      //   area: 0,
      //   area_sub: '',

      //   visa: '',
      //   visa_validity_from: '',
      //   visa_validity_to: '',
      //   estimated_stay: '',
      //   stay_reason: '', // Bekerja, Belajar, Lainnya
      //   stay_description: '',
      //   stay_name: '', // nama sekolah, nama perusahaan
      //   stay_address: '', // alamat sekolah, alamat perusahaan
      //   stay_data1: '', // Bidang Kerja, Jenjang Sekolah
      //   stay_data2: '', // Pekerjaan, Program Studi
      //   stay_education_length: '', // If Belajar
      //   stay_pptkis: '', // If Pekerjaan
      //   stay_agent: '', // If Pekerjaan / Agen Penyalur di Luar Negeri
      //   stay_notes: '',
      //   stay_ktkln: '', // If Pekerjaan (Kartu Tenaga Kerja Luar Negeri)

      //   emergency_ln: '',
      //   emergency_relation_ln: '',
      //   emergency_email_ln: '',
      //   emergency_phone_code_ln: '+60',
      //   emergency_phone_ln: '',

      //   emergency: '',
      //   emergency_relation: '',
      //   emergency_email: '',
      //   emergency_phone_code: '+62',
      //   emergency_phone: '',

      //   img: '',
      //   img_nik: '',
      //   img_passport: '',
      //   rauth: '',
      //   verified_email: 0,
      //   verified_phone: 0,
      //   verified_email_code: '',
      //   verified_phone_code: '',
      //   fcm_ids: '',
      //   status: 1,
      //   role: 'customer',
      //   notes: ''
      // }
    },
    parsed (d) {
      if (d.SheetNames.length) {
        this.sheets = d.Sheets || {}
        this.sheetSelected = null
        this.dialogConfirmUpload = true
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Tidak ada sheet/halaman pada file!' })
      }
    },
    IMPORT_DATA () {
      this.sheets = []
      this.sheetSelected = null
      let u = document.getElementById('file-uploader')
      if (u) {
        u.remove()
      }
      setTimeout(() => {
        u = document.createElement('input')
        u.setAttribute('type', 'file')
        u.setAttribute('id', 'file-uploader')
        u.setAttribute('class', 'file-uploader')
        u.setAttribute('accept', '.xls,.xlsx')
        u.addEventListener('change', (e) => {
          this.file = e.target.files ? e.target.files[0] : null
        })
        document.body.appendChild(u)
        u.click()
      }, 100)
    }
  }
}
</script>
